/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        jQuery(document).ready(function() {
          if(jQuery('body').innerWidth() <= 767) {
            jQuery('#primaryMenu').removeClass('in');
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        //SMOOTH SCROLL TO ANCHOR
        /*$('a[href*="#"]:not([href="#"])').click(function() {

          if(typeof $(this).data('vc-tabs') === 'undefined'){
            if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name="' + this.hash.slice(1) +']"');
              if (target.length) {
                $('html,body').animate({
                  scrollTop: target.offset().top
                }, 1000);
                return false;
              }
            }
          }
        });//*/

        $(function () {
          //$('[data-toggle="tooltip"]').tooltip({'trigger':'click'});
        });

        var initial_font_size = parseInt($('html').css('font-size'));
        $('.font-handler').find('button').on('click', function(){

          var current_font_size = parseInt($('html').css('font-size'));
          if($(this).hasClass('lower')){
            new_font_size = current_font_size * 0.85;

            if(new_font_size < initial_font_size){
              new_font_size = initial_font_size;
            }

          }else if($(this).hasClass('upper')){
            new_font_size = current_font_size * 1.15;
          }
          $('html').css('font-size', new_font_size);

        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        var tags = {};
        $( ".autocomplete" ).autocomplete({
          source: function( request, response ) {
            tags = $(this.element).data('collection');
            var matcher = new RegExp( "^" + $.ui.autocomplete.escapeRegex( request.term ), "i" );
            response( $.grep( tags, function( item ){
              return matcher.test( item );
            }) );
          }
        });

/*
        $( ".select-ui" ).selectmenu({
          position: { my : "right top", at: "right bottom", collision: "none" }
        }).selectmenu( "menuWidget" ).addClass( "ddloverflow" );//*/
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
